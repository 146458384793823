import React, { useCallback } from 'react';
import style from './MultifileUpload.module.css';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export interface MultifileUploadProps {
  onDrop: (acceptedFiles: File[]) => void;
}

const MultifileUpload = (props: MultifileUploadProps) => {
  const { t } = useTranslation();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    props.onDrop(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const dropzoneClasses = [style.dropzone];

  if (isDragActive) {
    dropzoneClasses.push(style.isDragActive);
  }

  return (
    <section className={style.root}>
      <div {...getRootProps({ className: style.dropzone })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <div className={style.title}>ОК</div>
        ) : (
          <>
            <div className={style.title}>{t('Choose a file')}</div>
            <div className={style.subtitle}>
              {t('or just drag and drop it here')}
            </div>
          </>
        )}
      </div>
      <div className={style.guide}>
        {t('JPEG, PNG, BMP, TIFF, GIF, DJVU files with size < 10 Mb')}.
        <br />
        {t('Multipage files and several documents in one file are supported')}.
      </div>
      {/* <aside>
        <h4>Files</h4>
        <ul>{files}</ul>
      </aside> */}
    </section>
  );
};

export default MultifileUpload;

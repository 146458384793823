import React, { FormEvent } from 'react';
import style from './UploadStage.module.css';
import MultifileUpload from '../MultifileUpload/MultifileUpload';
import TextInput from '../TextInput/TextInput';
import Heading from '../Heading/Heading';
import Container from '../Container/Container';

import { useTranslation } from 'react-i18next';
export interface UploadStageProps {
  token: string;
  tokenError?: string;
  onTokenChange: (value: string) => void;
  onDrop: (acceptedFiles: File[]) => void;
}

const UploadStage = (props: UploadStageProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={style.tokenSection}>
        <div className={style.tokenForm}>
          <Container>
            <Heading weight={4}>{t('Enter your token')}</Heading>
            <TextInput
              placeholder=""
              focus
              type="password"
              required
              error={props.tokenError ? t(props.tokenError) : undefined}
              value={props.token}
              onChange={(event: FormEvent<HTMLInputElement>) =>
                props.onTokenChange(event.currentTarget.value.slice(0, 100))
              }
              autoComplete="no"
            />
          </Container>
        </div>
      </div>
      <Container>
        <MultifileUpload onDrop={props.onDrop} />
      </Container>
    </div>
  );
};

export default UploadStage;

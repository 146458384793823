import { AppStage } from '../App';
import { DocumentInfo, RecognizedFields } from '../@types/api';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_CURRENT_STAGE = 'SET_CURRENT_STAGE';
export const START_CLASSIFY = 'START_CLASSIFY';
export const CLASSIFY_SUCCEEDED = 'CLASSIFY_SUCCEEDED';
export const RECOGNIZE_SUCCEEDED = 'RECOGNIZE_SUCCEEDED';
export const START_RECOGNIZE = 'START_RECOGNIZE';
export const SET_APP_ERROR = 'SET_APP_ERROR';
export const CLEAR_APP_ERROR = 'CLEAR_APP_ERROR';
export const SET_PROGRESS = 'SET_PROGRESS';
export const SET_DOCUMENT_PROGRESS = 'SET_DOCUMENT_PROGRESS';
export const SET_DOCUMENT_LOADING = 'SET_DOCUMENT_LOADING';
export const RESET_APP = 'RESET_APP';

export enum AppError {
  Forbidden = 'Forbidden',
  InternalServerError = 'InternalServerError',
  ServiceUnavailable = 'ServiceUnavailable',
  NullResponse = 'NullResponse',
  Other = 'Other',
}

export enum AppLanguage {
  Ru = 'ru',
  En = 'en',
}

export interface AppState {
  token: string;
  documents: DocumentInfo[];
  currentStage: AppStage;
  progress: number;
  appError?: AppError;
  tokenError?: string;
}

export interface SetTokenAction {
  type: typeof SET_TOKEN;
  payload: string;
}

export interface SetCurrentStageAction {
  type: typeof SET_CURRENT_STAGE;
  payload: AppStage;
}

export interface StartClassifyAction {
  type: typeof START_CLASSIFY;
  payload: File[];
}

export interface StartRecognizeAction {
  type: typeof START_RECOGNIZE;
  payload: StartRecognizePayload;
}

export interface ClassifySucceededAction {
  type: typeof CLASSIFY_SUCCEEDED;
  payload: DocumentInfo[];
}

export interface RecognizeSucceededAction {
  type: typeof RECOGNIZE_SUCCEEDED;
  payload: RecognizeSucceededPayload;
}

export interface SetAppErrorAction {
  type: typeof SET_APP_ERROR;
  payload: AppError;
}
export interface SetProgressAction {
  type: typeof SET_PROGRESS;
  payload: number;
}

export interface SetDocumentProgressPayload {
  id: string;
  progress: number;
}

export interface SetDocumentProgressAction {
  type: typeof SET_DOCUMENT_PROGRESS;
  payload: SetDocumentProgressPayload;
}

export interface SetDocumentLoadingAction {
  type: typeof SET_DOCUMENT_LOADING;
  payload: SetDocumentLoadingPayload;
}

export interface SetDocumentLoadingPayload {
  id: string;
  isLoading: boolean;
}

export interface ClearAppErrorAction {
  type: typeof CLEAR_APP_ERROR;
}

export interface ResetAppError {
  type: typeof RESET_APP;
}

export interface StartRecognizePayload {
  document: DocumentInfo;
  withHitl: boolean;
}

export interface RecognizeSucceededPayload {
  document: DocumentInfo;
  fields: RecognizedFields;
}

export type ActionTypes =
  | SetTokenAction
  | StartClassifyAction
  | ClassifySucceededAction
  | SetCurrentStageAction
  | StartRecognizeAction
  | RecognizeSucceededAction
  | SetAppErrorAction
  | ClearAppErrorAction
  | SetProgressAction
  | SetDocumentProgressAction
  | SetDocumentLoadingAction
  | ResetAppError;

import React from 'react';
import style from './ClassifyingStage.module.css';
import Heading from '../Heading/Heading';
import { useTranslation } from 'react-i18next';

export default function ClassifyingStage() {
  const { t } = useTranslation();
  return (
    <div className={style.root}>
      <Heading weight={3}>{t('Classifying...')}</Heading>
    </div>
  );
}

import React from 'react';
import style from './Footer.module.css';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className={style.root}>
      <a href="https://latest.handl.ai/docs/" target="_blank">
        {t('Documentation')}
      </a>
      <a href="https://latest.handl.ai/try" target="_blank">
        {t('Try API')}
      </a>
    </div>
  );
}

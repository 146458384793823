import React from 'react';
import style from './RecognizeStageItem.module.css';
import Button, { ButtonTheme, ButtonSize } from '../Button/Button';
import Select, { SelectItem } from '../Select/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DocType } from '../../@types/common';
import RecognizeStats, {
  RecognizeStatsItem,
} from '../RecognizeStats/RecognizeStats';
import { Mapper } from '../../utils/mapper';
import MagnifyImage from '../MagnifyImage/MagnifyImage';
import CheckBoxChecked from '../CheckBoxChecked/CheckBoxChecked';
import CheckBoxNotChecked from '../CheckBoxNotChecked/CheckBoxNotChecked';
import LoadingBar from '../LoadingBar/LoadingBar';
import { downloadCsv } from '../../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { DocumentInfo } from '../../@types/api';
import { StartRecognizePayload, AppLanguage } from '../../store/types';
import {
  WithHitlDocumentList,
  FieldnetDocumentList,
} from '../../utils/docFields';
import Fade from '@material-ui/core/Fade';
import Popup from '../Popup/Popup';
import CloseButton from '../CloseButton/CloseButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Container from '../Container/Container';
import { useTranslation } from 'react-i18next';

export interface RecognizeStageItemProps {
  documentInfo: DocumentInfo;
  isLoading?: boolean;
  progress?: number;
  onRecognize: (payload: StartRecognizePayload) => void;
}

export default function RecognizeStageItem({
  documentInfo,
  onRecognize,
  isLoading,
  progress,
}: RecognizeStageItemProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [selected, setSelected] = React.useState<string>(documentInfo.docType);
  const [withHitl, setWithHitl] = React.useState<boolean>(false);
  const [isPopup, setPopup] = React.useState<boolean>(false);
  const [isRecognizeHintVisible, setRecognizeHintVisible] = React.useState<
    boolean
  >(false);
  const [isVerbalConfidence, setVerbalConfidence] = React.useState<boolean>(
    true,
  );
  const [isReadableFields, setReadableFields] = React.useState<boolean>(true);

  const getReadableSelectItems = (): SelectItem[] => {
    return Object.values(DocType).map((val: DocType) => ({
      value: val,
      text:
        language.toLowerCase() === AppLanguage.Ru
          ? Mapper.mapDocType(val)
          : val,
    }));
  };

  const handleCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void = (e, checked) => setWithHitl(checked);

  const convertToCsvRows = (items: RecognizeStatsItem[]): string[][] => {
    return items.map((item: RecognizeStatsItem) => [
      item.name,
      item.value,
      item.confidenceNumber + '',
    ]);
  };

  const handleDownloadCsv = () => {
    if (documentInfo.fields) {
      downloadCsv({
        rows: convertToCsvRows(
          Mapper.mapRecognizedDataToItems(documentInfo.fields),
        ),
        filename: `${documentInfo.docType}-${uuidv4()}.csv`,
      });
    }
  };

  const isWithHitl = (docType: string) => {
    return WithHitlDocumentList.includes(docType);
  };

  const isRecognizeDisable = (docType: string) => {
    return !FieldnetDocumentList.includes(docType);
  };

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className={style.imageContainer}>
          <MagnifyImage
            src={documentInfo.crop}
            className={style.imageContainer}
          />
        </div>

        {documentInfo.fields ? (
          <div className={style.stats}>
            {isMobile ? (
              <>
                <Button
                  theme={ButtonTheme.Green}
                  size={ButtonSize.L}
                  onClick={() => setPopup(true)}
                >
                  {t('Extracted Data')}
                </Button>
                <Popup isOn={isPopup}>
                  <div className={style.popup}>
                    <Container>
                      <div className={style.closePopup}>
                        <CloseButton onClose={() => setPopup(false)} />
                      </div>
                      <RecognizeStats
                        isMobile
                        docType={documentInfo.docType}
                        items={Mapper.mapRecognizedDataToItems(
                          documentInfo.fields,
                        )}
                        isReadableFields={isReadableFields}
                        isVerbalConfidence={isVerbalConfidence}
                        onToggleReadableFields={() =>
                          setReadableFields(!isReadableFields)
                        }
                        onToggleVerbalConfidence={() =>
                          setVerbalConfidence(!isVerbalConfidence)
                        }
                      />
                    </Container>
                  </div>
                </Popup>
              </>
            ) : (
              <RecognizeStats
                docType={documentInfo.docType}
                items={Mapper.mapRecognizedDataToItems(documentInfo.fields)}
                isReadableFields={isReadableFields}
                isVerbalConfidence={isVerbalConfidence}
                onToggleReadableFields={() =>
                  setReadableFields(!isReadableFields)
                }
                onToggleVerbalConfidence={() =>
                  setVerbalConfidence(!isVerbalConfidence)
                }
              />
            )}

            <div className={style.csv}>
              <Button
                theme={ButtonTheme.Blue}
                size={ButtonSize.L}
                onClick={handleDownloadCsv}
              >
                {t('Download CSV')}
              </Button>
            </div>
          </div>
        ) : (
          <div className={style.manage}>
            {!!isLoading ? (
              <div className={style.loadingBar}>
                <LoadingBar progress={progress} />
              </div>
            ) : (
              <>
                <div className={style.select}>
                  <Select
                    selected={selected}
                    onChange={setSelected}
                    items={getReadableSelectItems()}
                  />
                </div>
                <div className={style.actionButtons}>
                  <Button
                    theme={ButtonTheme.Blue}
                    size={ButtonSize.L}
                    disabled={isRecognizeDisable(documentInfo.docType)}
                    onMouseOver={() => setRecognizeHintVisible(true)}
                    onMouseLeave={() => setRecognizeHintVisible(false)}
                    onClick={() =>
                      onRecognize({
                        document: documentInfo,
                        withHitl: withHitl,
                      })
                    }
                  >
                    {t('Extract Data')}
                  </Button>
                  <Fade
                    in={
                      isRecognizeHintVisible &&
                      isRecognizeDisable(documentInfo.docType)
                    }
                  >
                    <span className={style.recognizeHint}>
                      {t(
                        "We can't recognize this document yet, but we're already learning how to",
                      )}
                    </span>
                  </Fade>

                  {isWithHitl(documentInfo.docType) && (
                    <FormControlLabel
                      className={style.formControlLabel}
                      control={
                        <Checkbox
                          icon={<CheckBoxNotChecked />}
                          checkedIcon={<CheckBoxChecked />}
                          name="checkedH"
                          checked={withHitl}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={t('Use HITL')}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import style from './RecognizeStats.module.css';
import { Confidence } from '../../@types/common';
import Badge from '../Badge/Badge';
import { v4 as uuidv4 } from 'uuid';
import BinaryToggler from '../BinaryToggler/BinaryToggler';
import VerticalBar from '../Icons/VerticalBar';
import { Mapper } from '../../utils/mapper';
import { AppLanguage } from '../../store/types';
import { useTranslation } from 'react-i18next';

export interface RecognizeStatsItem {
  name: string;
  value: string;
  confidenceLevel: Confidence;
  confidenceNumber: number;
}
export interface RecognizeStatsProps {
  items: RecognizeStatsItem[];
  isReadableFields: boolean;
  isVerbalConfidence: boolean;
  docType: string;
  isMobile?: boolean;
  onToggleReadableFields: () => void;
  onToggleVerbalConfidence: () => void;
}

const RecognizeStats = (props: RecognizeStatsProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { isMobile } = props;

  const getNumericConfidence = (conf: number) => {
    return conf === 0
      ? 'Поле на документе отсутствует'
      : Math.round(conf * 1000) / 1000;
  };

  const getConfidence = ({
    confidenceLevel,
    confidenceNumber,
  }: RecognizeStatsItem) => {
    if (confidenceNumber === 0) return t('No field detected');

    return props.isVerbalConfidence
      ? t(Mapper.mapConfidenceToReadable(confidenceLevel))
      : getNumericConfidence(confidenceNumber);
  };

  return (
    <div className={style.root}>
      <div className={style.header}>
        <div className={style.gridHeaderItem}>
          {language.toLowerCase() === AppLanguage.Ru && (
            <BinaryToggler
              iconLeft={'РУ'}
              iconRight={'</>'}
              value={props.isReadableFields}
              onToggle={props.onToggleReadableFields}
            />
          )}
        </div>
        {isMobile ? <></> : <div className={style.gridHeaderItem}></div>}

        <div className={style.gridHeaderItem}>
          <BinaryToggler
            iconLeft={<VerticalBar />}
            iconRight={'%'}
            value={props.isVerbalConfidence}
            onToggle={props.onToggleVerbalConfidence}
          />
        </div>
        {isMobile ? (
          <>
            <div className={style.gridHeaderItem}>{t('Field and Values')}</div>
            <div className={style.gridHeaderItem}>{t('Confidence')}</div>
          </>
        ) : (
          <>
            <div className={style.gridHeaderItem}>{t('Field')}</div>
            <div className={style.gridHeaderItem}>{t('Value')}</div>
            <div className={style.gridHeaderItem}>{t('Confidence')}</div>
          </>
        )}
      </div>
      <div className={style.grid}>
        {props.items.map((item, index) => {
          return (
            <div className={style.gridRow} key={index}>
              {isMobile ? (
                <>
                  <div className={style.gridItem} key={uuidv4()}>
                    <div className={style.mobileName}>
                      {props.isReadableFields &&
                      language.toLowerCase() === AppLanguage.Ru
                        ? Mapper.mapDocumentFieldName(props.docType, item.name)
                        : item.name}
                    </div>
                    <div className={style.mobileValue}>{item.value}</div>
                  </div>
                </>
              ) : (
                <>
                  <div className={style.gridItem} key={uuidv4()}>
                    <div className={style.fieldName}>
                      {props.isReadableFields &&
                      language.toLowerCase() === AppLanguage.Ru
                        ? Mapper.mapDocumentFieldName(props.docType, item.name)
                        : item.name}
                    </div>
                  </div>
                  <div className={style.gridItem} key={uuidv4()}>
                    <div className={style.fieldValue}> {item.value}</div>
                  </div>
                </>
              )}

              <div className={style.gridItem} key={uuidv4()}>
                <Badge mode={item.confidenceLevel}>{getConfidence(item)}</Badge>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecognizeStats;

import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AppLanguage } from '../store/types';

export const i18nInit = (lang: AppLanguage) =>
  i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: {
          translation: {
            'Enter your token': 'Enter your token',
            Dbrain: 'Handl',
            Documentation: 'Documentation',
            'Try API': 'Try API',
            'Choose a file': 'Choose a file',
            'or just drag and drop it here': 'or just drag and drop it here',
            'JPEG, PNG, BMP, TIFF, GIF, DJVU files with size < 10 Mb':
              'JPEG, PNG, BMP, TIFF, GIF, DJVU files with size < 10 Mb',
            'Multipage files and several documents in one file are supported':
              'Multipage files and several documents in one file are supported',
            'Loading your file...': 'Loading your file...',
            'Classifying...': 'Classifying...',
            'Go back': 'Go back',
            'Go home': 'Go home',
            'You entered an invalid token': 'You entered an invalid token',
            'Please try again or contact the Handl team':
              'Please try again or contact the Handl team',
            'Error 500': 'Something went wrong',
            'Error 503': 'Something went wrong',
            'Error 500. Please try again or contact the Handl team':
              'Error 500. Please try again or contact the Handl team',
            'Error 503. Please try again or contact the Handl team':
              'Error 503. Please try again or contact the Handl team',
            'No fields found in the document':
              'No fields found in the document',
            'Please choose a different type of a document. If the document type is correct, please contact the Handl team':
              'Please choose a different type of a document. If the document type is correct, please contact the Handl team',
            'Extract Data': 'Extract Data',
            'Use HITL': 'Use HITL',
            "We can't recognize this document yet, but we're already learning how to":
              "We can't recognize this document yet, but we're already learning how to",
            'Extracted Data': 'Extracted Data',
            'Download CSV': 'Download CSV',
            'Unknown error': 'Unknown error',
            'Please contact us': 'Please contact us',
            Field: 'Field',
            Confidence: 'Confidence',
            Value: 'Value',
            'Field and Values': 'Field and Values',
            'No field detected': 'No field detected',
            Low: 'Low',
            Medium: 'Medium',
            High: 'High',
            'You entered an invalid token. Please try again or contact the Handl team':
              'You entered an invalid token. Please try again or contact the Handl team',
          },
        },
        ru: {
          translation: {
            'Enter your token': 'Введите токен',
            Dbrain: 'Dbrain',
            Documentation: 'Документация',
            'Try API': 'Попробовать API',
            'Choose a file': 'Выберите файл для распознания',
            'or just drag and drop it here': 'или просто перетащите его сюда',
            'JPEG, PNG, BMP, TIFF, GIF, DJVU files with size < 10 Mb':
              'Форматы JPEG, PNG, BMP, TIFF, GIF, PDF, DJVU — весом до 10 МБ',
            'Multipage files and several documents in one file are supported':
              'Поддерживаются многостраничные файлы <br /> и распознавание нескольких документов в одном файле',
            'Loading your file...': 'Загружаем...',
            'Classifying...': 'Классифицируем...',
            'Go back': 'В начало',
            'You entered an invalid token': 'Вы ввели неправильный токен',
            'Please try again or contact the Handl team':
              'Попробуйте еще раз или обратитесь к&nbsp;сотрудникам Dbrain',
            'Error 500': 'Ошибка 500',
            'Error 503': 'Ошибка 503',
            'Error 500. Please try again or contact the Handl team':
              'Пожалуйста, отправьте код ошибки и файл сотруднику Dbrain',
            'Error 503. Please try again or contact the Handl team':
              'Пожалуйста, отправьте код ошибки и файл сотруднику Dbrain',
            'No fields found in the document': 'Поля в документе не найдены',
            'Please choose a different type of a document. If the document type is correct, please contact the Handl team':
              'Пожалуйста, выберете другой тип документа. Если тип документа правильный, пожалуйста, отправьте документ сотруднику Dbrain',
            'Extract Data': 'Распознать',
            "We can't recognize this document yet, but we're already learning how to":
              'Мы такое еще не умеем распознавать, <br /> но уже учимся',
            'Extracted Data': 'Расшифровка',
            'Use HITL': 'Использовать HITL',
            'Download CSV': 'Скачать CSV',
            'Unknown error': 'Неизвестная ошибка',
            'Please contact us': 'Пожалуйста, свяжитесь с нами',
            Field: 'Поле',
            Confidence: 'Уверенность',
            Value: 'Значение',
            'Field and Values': 'Поля и Значения',
            'No field detected': 'Поле не найдено',
            Low: 'Низкая',
            Medium: 'Средняя',
            High: 'Высокая',
            'You entered an invalid token. Please try again or contact the Handl team':
              'Токен неверный. Попробуйте еще раз или обратитесь к сотрудникам Dbrain',
            'Go home': 'На&nbsp;главную',
          },
        },
      },
      lng: lang,
      fallbackLng: 'ru',

      interpolation: {
        escapeValue: false,
      },
    });

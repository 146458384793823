import {
  AppState,
  ActionTypes,
  SET_TOKEN,
  CLASSIFY_SUCCEEDED,
  SET_CURRENT_STAGE,
  SET_APP_ERROR,
  RECOGNIZE_SUCCEEDED,
  CLEAR_APP_ERROR,
  SET_PROGRESS,
  AppError,
  RESET_APP,
  SET_DOCUMENT_PROGRESS,
  SET_DOCUMENT_LOADING,
} from './types';
import { AppStage } from '../App';
import { LocalStorage } from '../utils/localStorage';

const initialState: AppState = {
  token: LocalStorage.getToken() || '',
  documents: [],
  currentStage: AppStage.Upload,
  progress: 0,
  appError: undefined,
  tokenError: undefined,
};

export function appReducer(
  state = initialState,
  action: ActionTypes,
): AppState {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_CURRENT_STAGE:
      return {
        ...state,
        currentStage: action.payload,
      };
    case CLASSIFY_SUCCEEDED:
      return {
        ...state,
        documents: action.payload,
      };
    case RECOGNIZE_SUCCEEDED:
      return {
        ...state,
        documents: state.documents.map((d) => {
          return d.id === action.payload.document.id
            ? {
                ...d,
                fields: action.payload.fields,
              }
            : d;
        }),
      };
    case SET_APP_ERROR:
      return {
        ...state,
        appError: action.payload,
        currentStage: AppStage.Upload,
        tokenError:
          action.payload === AppError.Forbidden
            ? 'You entered an invalid token. Please try again or contact the Handl team'
            : '',
        progress: 0,
      };
    case CLEAR_APP_ERROR:
      return {
        ...state,
        currentStage: AppStage.Upload,
        appError: undefined,
      };
    case SET_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case SET_DOCUMENT_PROGRESS:
      return {
        ...state,
        documents: state.documents.map((d) => {
          return d.id === action.payload.id
            ? {
                ...d,
                recognizeProgress: action.payload.progress,
              }
            : d;
        }),
      };
    case SET_DOCUMENT_LOADING:
      return {
        ...state,
        documents: state.documents.map((d) => {
          return d.id === action.payload.id
            ? {
                ...d,
                isRecognizeLoading: action.payload.isLoading,
              }
            : d;
        }),
      };
    case RESET_APP:
      return {
        ...state,
        currentStage: AppStage.Upload,
        tokenError: undefined,
        documents: [],
      };
    default:
      return state;
  }
}

import React from 'react';
import ReactImageMagnify from 'react-image-magnify';
import style from './MagnifyImage.module.css';

export interface MagnifyImageProps {
  src: string;
  className: string;
}

class MagnifyImage extends React.PureComponent<MagnifyImageProps> {
  state = {
    isLoaded: false,
    isMagnify: false,
    isFixedPosition: false,
    clientRect: undefined,
  };

  img?: HTMLImageElement = undefined;

  componentDidMount() {
    const { src } = this.props;
    this.img = new Image();

    this.img.addEventListener('load', this.handleLoad);
    this.img.src = src;
  }

  handleLoad = () => {
    const isMagnify =
      (this.img && this.img.naturalHeight > 500) ||
      (this.img && this.img.naturalWidth > 400);
    this.setState({
      isMagnify,
      isLoaded: true,
    });
  };

  renderImage = () => {
    const { src, className } = this.props;
    const { isMagnify } = this.state;

    return isMagnify ? (
      <ReactImageMagnify
        imageClassName={style.root}
        className={className}
        enlargedImageContainerDimensions={{
          width: '100%',
          height: '100%',
        }}
        enlargedImagePosition="over"
        {...{
          smallImage: {
            isFluidWidth: true,
            src,
          },
          largeImage: {
            src,
            width: (this.img && this.img.naturalWidth) || 100,
            height: (this.img && this.img.naturalHeight) || 100,
          },
        }}
      />
    ) : (
      <img src={src} alt="" className="image-img" />
    );
  };

  render() {
    return this.renderImage();
  }
}

export default MagnifyImage;

import React from 'react';
import style from './BinaryToggler.module.css';

export interface BinaryTogglerProps {
  value: boolean;
  iconLeft: React.ReactNode;
  iconRight: React.ReactNode;
  onToggle: () => void;
}

export default function BinaryToggler(props: BinaryTogglerProps) {
  return (
    <div
      className={[style.root, ...(props.value ? [style.active] : [])].join(' ')}
      onClick={props.onToggle}
    >
      <div className={style.box}></div>
      <div
        className={[
          style.item,
          ...(props.value ? [style.activeItem] : []),
        ].join(' ')}
      >
        {props.iconLeft}
      </div>
      <div
        className={[
          style.item,
          ...(!props.value ? [style.activeItem] : []),
        ].join(' ')}
      >
        {props.iconRight}
      </div>
    </div>
  );
}

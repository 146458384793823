import axios, { AxiosResponse } from 'axios';
import store from '../store';
import { StartRecognizePayload } from '../store/types';
import { ClassifyResponse, RecognizeResponseData } from '../@types/api';

const host = 'https://api.dbrain.io';

export class Api {
  static async classify(acceptedFiles: File[]): Promise<AxiosResponse<any>> {
    const state = store.getState();

    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('image', file);

    const response = axios.post<ClassifyResponse>(
      `${host}/classify`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          mode: 'default',
          quality: 75,
          dpi: 300,
          pdf_raw_images: true,
          token: state.token,
        },
      },
    );
    return response;
  }

  static async recognize({
    document,
    withHitl,
  }: StartRecognizePayload): Promise<AxiosResponse<any>> {
    const state = store.getState();

    const res = await fetch(document.crop);
    const blob = await res.blob();

    var formData = new FormData();
    formData.append('image', blob);

    return axios.post<RecognizeResponseData>(`${host}/recognize`, formData, {
      // timeout: 20000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        doc_type: document.docType,
        mode: 'default',
        with_hitl: withHitl,
        hitl_sla: 'night',
        quality: 75,
        dpi: 300,
        pdf_raw_images: true,
        token: state.token,
      },
    });
  }
}
